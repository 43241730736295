<template>
  <div class="">
    <!-- BODY -->
    <b-overlay
      :show="show"
      rounded="sm"
      :opacity="0.85"
      variant="white"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="24"
          />
          <p id="cancel-label">
            Guardando datos espere por favor...
          </p>
        </div>
      </template>

      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Crear Empresa y contacto
          </b-card-title>
        </b-card-header>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Crear empresa
                    </h5>
                    <small class="text-muted">
                      Ingresa el RUC/DNI para buscar si existe la empresa.
                    </small>
                  </b-col>
                  <b-col md="6">

                    <b-form-group
                      label="RUC/DNI"
                      label-for="ruc_dni"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="RUC / DNI"
                        :rules="{ required: true, numeric:true, digits_8_or_11: true }"
                      >
                        <b-form-input
                          id="ruc_dni"
                          v-model="businessContactData.business.ruc"
                          maxlength="11"
                          :state="errors.length > 0 ? false:null"
                          @input="checkIfRucExists"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <div
                          v-if="existsInfoBusiness.businessName"
                          class=""
                        >
                          <p class="mb-0 mt-1"><b>Empresa: {{ existsInfoBusiness.businessName }}</b>
                          </p>
                          <p><b>Vendedor: {{ existsInfoBusiness.sellerName }}</b></p>
                        </div>
                        <b-alert
                          class="mt-2"
                          variant="danger"
                          :show="!userLoggerIsOwner"
                        >
                          <div class="alert-body">
                            Esta empresa pertenece a otro vendedor contáctate con él.
                          </div>
                        </b-alert>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Razón social"
                      label-for="business_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Razón social"
                        rules="required"
                      >
                        <b-form-input
                          id="business_name"
                          v-model="businessContactData.business.business_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Dirección fiscal"
                      label-for="address_fiscal"
                    >
                      <b-form-input
                        id="address_fiscal"
                        v-model="businessContactData.business.address_fiscal"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Departamento"
                      label-for="department"
                    >
                      <b-form-input
                        id="department"
                        v-model="businessContactData.business.department"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Provincia"
                      label-for="province"
                    >
                      <b-form-input
                        id="province"
                        v-model="businessContactData.business.province"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Distrito"
                      label-for="district"
                    >
                      <b-form-input
                        id="district"
                        v-model="businessContactData.business.district"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">

                    <!-- Service of interest -->
                    <b-form-group
                      v-if="!existsInfoBusiness.status"
                      label="Servicio de interes"
                      label-for="service_interest"
                    >
                      <v-select
                        v-model="businessContactData.business.service_interest"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="serviceInterest"
                        :clearable="false"
                        :reduce="val => val.value"
                        input-id="service_interest"
                      />
                    </b-form-group>

                  </b-col>

                </b-row>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Crear contacto
                    </h5>
                    <small class="text-muted">
                      Ingrese el correo para verificar si existe.
                    </small>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        :rules="{ required: true, email: true, uniqueEmail: true }"
                      >
                        <b-form-input
                          id="email"
                          v-model="businessContactData.contact.email"
                          :disabled="disableSearchContactEmail"
                          :state="errors.length > 0 ? false:null"
                          @input="existsContactValidate"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <div
                          v-if="existsInfoContact.contactName"
                          class=""
                        >
                          <p class="mb-0 mt-1"><b>Contacto: {{ existsInfoContact.contactName }}</b>
                          </p>
                          <p><b>Vendedor: {{ existsInfoContact.sellerName }}</b></p>
                        </div>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      v-if="existsInfoContact.status"
                      label="Nombre"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="businessContactData.contact.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      v-if="existsInfoContact.status"
                      label="Apellido"
                      label-for="lastname"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Apellido"
                        rules="required"
                      >
                        <b-form-input
                          id="lastname"
                          v-model="businessContactData.contact.lastname"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      v-if="existsInfoContact.status"
                      label="Celular"
                      label-for="mobilePhone"
                    >
                      <cleave
                        id="number"
                        v-model="businessContactData.contact.mobilePhone"
                        class="form-control"
                        :options="options.number"
                        placeholder="___ ___ ___"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- Email Additional -->
                    <b-form-group
                      v-if="existsInfoContact.status"
                      label="Email adicional"
                      label-for="emailAdditional"
                    >
                      <b-form-input
                        id="emailAdditional"
                        v-model="businessContactData.contact.emailAdditional"
                        trim
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- Position in Company -->
                    <b-form-group
                      v-if="existsInfoContact.status"
                      label="Cargo"
                      label-for="position"
                    >
                      <b-form-input
                        id="position"
                        v-model="businessContactData.contact.position"
                        trim
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>

            <!-- Form Actions -->
            <div class="d-flex justify-content-end mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!userLoggerIsOwner"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="saveAndRedirect = 'only_save'"
              >
                <!--              <b-spinner small />-->
                Crear
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!userLoggerIsOwner"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="saveAndRedirect = 'save_and_redirect_pen'"
              >
                Crear y cotizar(PEN)
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!userLoggerIsOwner"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="saveAndRedirect = 'save_and_redirect_usd'"
              >
                Crear y cotizar(USD)
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$router.push({ name: 'dashboard'})"
              >
                Cancelar
              </b-button>
            </div>

          </b-form>

        </validation-observer>
      </b-card>
    </b-overlay>

  </div>

</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import businessStoreModule from '@/views/dramox/accounts/business/businessStoreModule'
import {
  BAlert,
  BButton,
  BCard, BCardHeader, BCardTitle,
  BCol, BForm, BFormGroup, BFormInput, BRow, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

extend('digits_8_or_11', {
  validate(value) {
    return /^(?:\d{8}|\d{11})$/.test(value)
  },
  message: 'El campo {_field_} debe tener 8 o 11 dígitos.',
})

extend('uniqueEmail', {
  // eslint-disable-next-line consistent-return
  async validate(value) {
    return new Promise((resolve, reject) => {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        store
          .dispatch('app-business/checkIfContactExists', { email: value })
          .then(response => {
            resolve(!response.data.payload.results.exists)
          })
      } else {
        resolve(true)
      }
    })
  },
  message: 'YA EXISTE ESTE EMAIL REGISTRADO EN EL SISTEMA',
})

export default {
  components: {
    BOverlay,
    BAlert,
    BButton,
    BCardTitle,
    BCardHeader,
    BCard,
    ValidationProvider,
    ValidationObserver,

    BRow,
    BFormInput,
    BCol,
    BFormGroup,
    BForm,
    vSelect,

    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      options: {
        number: {
          delimiters: [' ', ' '],
          blocks: [3, 3, 3],
          numericOnly: true,
        },
      },
    }
  },
  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'app-business'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, businessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const show = ref(false)

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const saveAndRedirect = ref('only_save')

    const blankBusinessContactData = {
      business: {
        ruc: '',
        business_name: '',
        address_fiscal: '',
        department: '',
        province: '',
        district: '',
        service_interest: '',
        business_id: null,
      },
      contact: {
        email: '',
        name: '',
        lastname: '',
        mobilePhone: '',
        emailAdditional: '',
        position: '',
      },
    }

    const existsInfoBusiness = ref({
      status: true,
      businessName: '',
      sellerName: '',
    })

    const existsInfoContact = ref({
      status: false,
      contactName: '',
      sellerName: '',
    })

    const disableSearchContactEmail = ref(true)

    const userLoggerIsOwner = ref(true)

    const businessContactData = ref(JSON.parse(JSON.stringify(blankBusinessContactData)))

    const resetCreateBusinessContactData = () => {
      businessContactData.value = JSON.parse(JSON.stringify(blankBusinessContactData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCreateBusinessContactData)

    const serviceInterest = ref([])

    store
      .dispatch('app-business/fetchServiceInterest')
      .then(response => {
        serviceInterest.value = response.data.payload.results
      })

    const onSubmit = () => {
      show.value = true
      store
        .dispatch('app-business/createBusinessAndContact', businessContactData.value)
        .then(response => {
          show.value = false
          const { contactId } = response.data.payload.results
          showNotification({ title: 'Empresa y contacto creado', icon: 'CheckCircleIcon', variant: 'success' })

          if (saveAndRedirect.value === 'save_and_redirect_pen') {
            router.push({ name: 'quotations-create', params: { currency: 'pen' }, query: { contactId } })
          } else if (saveAndRedirect.value === 'save_and_redirect_usd') {
            router.push({ name: 'quotations-create', params: { currency: 'usd' }, query: { contactId } })
          } else {
            resetForm()
            existsInfoBusiness.value = {
              status: true,
              businessName: '',
              sellerName: '',
            }

            existsInfoContact.value = {
              status: false,
              contactName: '',
              sellerName: '',
            }

            disableSearchContactEmail.value = true
            userLoggerIsOwner.value = true
          }
        })
        .catch(err => {
          show.value = false
          console.log(err, 'error')
          showNotification({ title: 'No se pudo crear la empresa ni el contato', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const checkIfRucExists = () => {
      const regex = /^(?:\d{8}|\d{11})$/
      if (regex.test(businessContactData.value.business.ruc)) {
        store
          .dispatch('app-business/checkIfRucExists', { ruc: businessContactData.value.business.ruc })
          .then(response => {
            existsInfoBusiness.value = {
              status: !!response.data.payload.results.exists,
              businessName: response.data.payload.results.businessName,
              sellerName: response.data.payload.results.sellerName,
            }
            businessContactData.value.business.business_id = response.data.payload.results.businessId

            userLoggerIsOwner.value = response.data.payload.results.isOwner
            if (userLoggerIsOwner.value) {
              disableSearchContactEmail.value = false
            }
          })
      } else {
        disableSearchContactEmail.value = true
        existsInfoBusiness.value = {
          status: true,
          businessName: '',
          sellerName: '',
        }
      }
    }

    const existsContactValidate = () => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const { email } = businessContactData.value.contact
      if (regex.test(email)) {
        store
          .dispatch('app-business/checkIfContactExists', { email })
          .then(response => {
            existsInfoContact.value = {
              status: !response.data.payload.results.exists,
              contactName: response.data.payload.results.contactName,
              sellerName: response.data.payload.results.sellerName,
            }
          })
      } else {
        existsInfoContact.value = {
          status: false,
          contactName: '',
          sellerName: '',
        }
      }
    }

    return {
      serviceInterest,
      businessContactData,

      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,

      existsInfoBusiness,
      existsInfoContact,
      disableSearchContactEmail,
      userLoggerIsOwner,

      checkIfRucExists,
      existsContactValidate,

      saveAndRedirect,

      show,

    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
