import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBusiness(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/business', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatistics(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/business/statistics')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceInterest() {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/services/interest')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBusiness(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/business/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBusiness(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/business/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBusiness(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/business/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentQualifications(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/payment/qualifications')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkIfRucExists(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/business/check-ruc', { params: body })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkIfContactExists(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/contacts/exists', {
            params: body,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBusinessAndContact(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/business/contact', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
